/*
$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome.scss";
*/

$purpleHathor: #8C46FF;
$purpleHathorHover: #5f2eaf;
$warning: #ce9c06;

:export {
  purpleHathor: $purpleHathor;
}

html, body, #root {
  height: 100%;
  width: 100%;
  word-wrap: break-word;

  // Keeping the same font family from Bootstrap 4
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

a {
  color: $purpleHathor;
  text-decoration: none; // Keeping style from Bootstrap 4
}

a:hover {
  color: $purpleHathorHover;
}

.content-wrapper {
  margin: 2rem auto;
  max-width: 988px;
  -webkit-margin-bottom-collapse: separate;
}

.d-flex-1 {
  flex-grow: 1;
}

.d-flex-2 {
  flex-grow: 2;
}

.d-flex-3 {
  flex-grow: 3;
}

.main-nav {
  background-color: #000;
}

.main-nav nav {
  max-width: 988px;
  margin: 0 auto;
  padding: 0.5rem 0;
}

.main-nav > nav a {
  color: rgb(255 255 255 / 60%);
}

.main-nav > nav a.active {
  color: rgb(255 255 255 / 90%);
}

.nav-tabs a, .nav-tabs a:hover {
  color: $purpleHathor;
}

.dropdown-menu a.nav-link {
  color: #212529 !important;
}

#navbarDropdown {
  cursor: pointer;
}

.version-wrapper {
  padding-left: 1rem;
}

.version-wrapper span {
  color: rgb(255 255 255 / 60%);
  font-size: 11px;
}

.tab-content-wrapper {
  padding: 2rem;
}

.pagination {
  padding-top: 2rem;
}

.page-item.active .page-link {
  background-color: $purpleHathor;
  border-color: $purpleHathor;
}

.page-link {
  color: $purpleHathor;
}

.page-link:hover {
  color: $purpleHathorHover;
}

#tx-table tbody tr {
  cursor: pointer;
}

#peer-table tbody td, #peer-table thead th, #tx-table tbody td, #tx-table thead th {
  text-align: center;
}

.tx-data-wrapper label {
  font-weight: bold;
}

.feature-column-descriptions label {
  font-weight: bold;
}

div.new-address-wrapper, button.send-tokens {
  margin-bottom: 1rem;
  margin-right: 1rem;
}

button.new-address {
  margin-right: 1rem;
}

div.new-address-wrapper span {
  margin-right: 1rem;
}

div.new-address-wrapper canvas {
  cursor: pointer;
}

#formSendTokens .outputs-wrapper input, #formSendTokens .inputs-wrapper input, #formSendTokens .wrapper input {
  margin-right: 1rem;
}

#formSendTokens .checkbox-wrapper {
  margin-bottom: 1rem;
}

.tx-input-wrapper textarea {
  margin: 1rem 0;
}

.tx-input-wrapper button {
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 160px;
}

.main-nav > nav .navbar-brand > img {
  height: 28px;
  width: auto;
}

#qrCodeModal .download-qrcode {
  margin-top: 1rem;
}

i.pointer {
  cursor: pointer;
}

.hathor-alert {
  position: fixed !important;
  right: 1rem;
  bottom: 1rem;
}

.navigation-search i {
  color: #fff;
}

.navigation-search input {
  border: none;
}

.navigation-search-token {
  max-width: 600px;
  padding-left: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.search-input {
  width: 400px;
}

.page-loader {
  padding-top: 2rem;
}

.navigation-search-token i {
  color: #000;
}

tr.tr-title {
  background-color: rgb(0 0 0 / 5%);
  cursor: default !important;
}

// Class to keep the same visual aspect from Bootstrap 4
.table {
  thead {
    tr {
      border-top: 1px solid #dee2e6;
    }

    th {
      border-bottom: 2px solid #dee2e6;
    }
  }

  tbody {
    tr.tr-title td {
      background-color: rgb(242 242 242);
      cursor: default !important;
    }

    td {
      padding: 12px;
    }
  }
}

tr.tr-title td {
  font-weight: bold;
}

svg.svg-wrapper {
  width:100%;
  height:250px
}

.svg-wrapper line {
  stroke: #ddd;
  shape-rendering: crispedges;
}

.svg-wrapper circle {
  fill-opacity: 0.5;
}

.svg-wrapper rect {
  fill-opacity: 0.5;
}

.dag-visualizer input {
  width: 60px;
}

.dag-visualizer btn {
  width: 70px;
}

@media (width <= 992px) {
  .content-wrapper {
    margin: 2rem;
  }

  .navbar-nav li.nav-item {
    padding: 0 1rem;
  }

  .navbar-right {
    padding: 0.5rem 1rem;
  }
}

.line {
  fill: none;
  stroke-width: 2px;
}

.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispedges;
}

.grid path {
  stroke-width: 0;
}

.hathor-alert {
  display: none;
}

.hathor-alert.show {
  display: block !important;
}

#graph {
  border: 1px solid;
}

#graph .tx, #graph .block {
  stroke: #000;
  stroke-width: 1.5px;
}

#graph .link {
  stroke: #666;
  stroke-width: 0.2px;
}

#graph .tx .tx-text, #graph .block .block-text {
  stroke-width: 0;
  font-size: 4px;
}

.tooltip {
  position: absolute;
  background-color: white;
  max-width: 200px;
  height: auto;
  padding: 1px;
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  box-shadow: 3px 3px 10px rgb(0 0 0 / 50%);
  pointer-events: none;
}

#graph {
  margin-top: 24px;
}

.dagWrapper {
  width: 100%;
}

.btn-hathor {
  background-color: $purpleHathor;
  border-color: $purpleHathor;
  color: white;
}

.btn-hathor:hover, .btn-hathor:active, .btn-hathor:visited, .btn-hathor:not(:disabled, .disabled):active {
  background-color: $purpleHathorHover;
  border-color: $purpleHathorHover;
  color: white;
}

.bordered-wrapper {
  flex-basis: 100%;
}

.bordered-wrapper > div {
  width: 100%;
  border: 2px solid #eee;
  border-bottom: 0;
}

.bordered-wrapper > div:first-child {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.bordered-wrapper > div:last-child {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom: 2px solid #eee;
}

.common-div > div {
  padding: 0.8rem;
}

.important-div > div {
  text-align: center;
  font-size: 1.3rem;
  padding: 1rem;
}

.config-string-wrapper, .token-nft-preview, .token-general-info {
  border-radius: 10px;
  border: 1px solid #eee;
  width: 320px;

  & > p {
    border-bottom: 1px solid #eee;
    margin: 0;
    padding: 1rem;

    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

.config-string-wrapper span {
  text-align: left;
}

.token-name {
  font-size: 1.5rem;
}

.token-wrapper i {
  font-size: 1.2rem;
  color: rgb(0 0 0 / 50%);
}

.token-general-info {
  margin: 0;
  word-break: break-all;

  & > p:first-child {
    background-color: #f9f9f9;
  }
}

.token-nft-preview {
  & figure {
    margin: 0;
  }

  & video, & img {
    border-radius: 5px;
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
  }
}

p, span {
  &.subtitle {
    opacity: 0.7;
    font-size: 0.85rem;
  }
}

.text-warning a {
  color: $warning !important;
}

.search-div {
  margin-bottom: 3rem;
  margin-top: 3rem;
  padding-left: 0;
}

#graph-funds svg, #graph-verification svg {
  width: 100%;
}

.navbar-dark .navbar-toggler {
  border: 0;
}

.dropdown-menu a.nav-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.address-history .spent-tx, .address-history .voided {
  text-decoration: line-through;
}

.address-history .input-tr .state, .address-history .input-tr .value, .sent-value {
  color: #dc3545;
}

.address-history .output-tr .state, .address-history .output-tr .value, .received-value {
  color: #28a745;
}

.address-history .state.voided, .address-history .value .voided {
  color: #6c757d !important;
}

.address-history .value {
	text-align: right;
	font-family: monospace;
	font-size: 1.2rem;
}

.address-history .voided-element {
  text-decoration: none;
  background-color: #6c757d;
  font-size: 0.75rem;
  width: 1.5rem;
  height: 0.75rem;
  padding: 0.4rem;
  border-radius: 0.75rem;
  color: white;
  margin-left: 1rem;
}

.color-hathor {
  color: $purpleHathor;
}

.refresh-alert a {
  color: #856404;
  text-decoration: underline;
}

.loading-wrapper {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & span {
    padding: 5px;
  }
}

.info-hover-wrapper {
  color: inherit;
  position: relative;

  & > i {
    position: static;
  }

  &:hover {
    color: inherit;

    .info-hover-popover {
      display: block;
    }
  }
}

.info-hover-popover {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: none;
  left: 0;
  margin-left: -50%;
  max-width: 300px;
  min-width: 200px;
  opacity: 1 !important;
  padding: 10px;
  position: absolute;
  white-space: normal;
  word-break: keep-all;
  top: 25px;
  z-index: 1;
}

.navigation-autocomplete-token {
  width: 100%;
  padding-left: 0;
  margin-top: 3rem;
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.autocomplete-input {}

.autocomplete-results {
  position: absolute;
  border: 1px solid #d4d4d4;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;

  /* Remove list styles */
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-left: 15px;
  margin-right: 38px;
  background: white;
  max-height: 400px;
  overflow-y: scroll;
}

.autocomplete-result-item {
  padding: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.autocomplete-result-item {
  border-bottom: 1px solid #e7e7e7;
}

.autocomplete-result-item:last-child {
  border-bottom: none;
}

.autocomplete-result-item:hover {
  background: #e7e7e7;
  cursor: pointer;
}

.autocomplete-selected-item {
  display: inline-block;
  background-color: #e6e4e4;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  border: 1px solid #d1d1d1;

  .close-icon {
    padding: 0;
    margin: 0;
    margin-left: 12px;
  }
}

.hidden {
  display: none;
}

.token-balances-information-wrapper {
  padding: 16px;
}

th.sortable {
  cursor: pointer;
}

.timeseries-iframe {
  display: flex;
  height: 2300px;
  width: 100%;
  border: none;
}

.statistics-title {
  padding-top: 15px;
  padding-bottom: 20px;
}

.screen-status {
  padding-bottom: 20px;
}

.table-method-arguments tbody tr {
  background-color: transparent !important;
}


.source-code code {
  background-color: #f2f2f2;
}

.source-code pre {
  height: 100%;
}

.source-code {
  transition: all 1s ease;
  height: 0;
  opacity: 0;
}

.source-code.show {
  height: auto !important;
  opacity: 1 !important;
}
